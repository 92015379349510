import React, { useState, useEffect } from "react";
import { getCookie, setCookie } from "../components/Cookie";
import curvedImage from '../assets/img/logos/logo2-removebg-preview.png';
//import curvedImage2 from '../assets/img/curved-images/logo-removebg-preview2.png';
import unidecode from 'unidecode';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { urlLoginSuperAdmin, urlRegisterCodeLoginSuperAdmin } from "../components/url";
function LoginSuperAdmin() {
    //Kiểm tra đăng nhập trang LoginSuperAdmin
    const navigate = useNavigate();
    const [isCode, setIsCode] = useState(false);// hiển thị trang mã xác thực

    //popup thông báo góc màn hình
    const [notifications, setNotifications] = useState([]);
    const addNotification = (message, btn, duration = 3000) => {
        const newNotification = {
            id: Date.now(),
            message,
            btn,
            duration,
        };
        setNotifications(prevNotifications => [...prevNotifications, newNotification]);
        setTimeout(() => {
            removeNotification(newNotification.id);
        }, duration);
    };
    const removeNotification = (id) => {
        setNotifications(prevNotifications =>
            prevNotifications.filter(notification => notification.id !== id)
        );
    };
    const NotificationContainer = ({ notifications }) => {
        return (
            <div className="notification-container">
                {notifications.map(notification => (
                    <div
                        key={notification.id}
                        className={` btn btn-${notification.btn}`}
                        onClick={() => removeNotification(notification.id)}
                    >
                        {notification.message}
                    </div>
                ))}
            </div>
        );
    };

    //--
    //hàm xử lý  bắt lỗi
    const [email, setEmail] = useState('');
    const [titleError, setTitleError] = useState('');
    const [error, setError] = useState(false);//hiển thị lỗi
    const [code, setCode] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);// trạng thái bấm nút đăng nhập
    const handleEmailChange = (event) => {
        setError(false);
        setEmail(event.target.value);
    };
    const handleCodeChange = (event) => {
        setError(false);
        setCode(event.target.value);
    };
    const handleSubmit2 = (e) => {
        e.preventDefault();
        if (!code) {
            setTitleError("Vui lòng nhập mã xác thực.");
            setError(true);
            setIsSubmitting(false); // Đặt isSubmitting thành false nếu có lỗi không nhập
            return;
        }
        if (isSubmitting) {
            return; // Ngăn chặn việc bấm nút nếu đã gửi yêu cầu trước đó
        }

        setIsSubmitting(true);

        setError(false);
        // Gọi API
        const data = {
            Code: code
        };
        fetch(urlRegisterCodeLoginSuperAdmin, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                setIsSubmitting(false);
                if (response.status === 200) {
                    return response.json();
                } else if (response.status === 401) {
                    return response.json().then(errorData => { throw new Error(errorData.message); });
                }
                else if (response.status === 400) {
                    return response.json().then(errorData => { throw new Error(errorData.message); });
                } else if (response.status === 500) {
                    return response.json().then(errorData => { throw new Error(errorData.message); });
                } else {
                    return;

                }
            })
            .then(data => {
                addNotification('Xác thực thành công', 'success', 3000)
                setCookie('loginsuperadmin', data.loginsuperadmin)
                navigate('/SuperAdmin')
            })
            .catch(error => {
                setIsSubmitting(false);
                if (error instanceof TypeError) {
                    console.log(error);
                    setTitleError("Không thể kết nối tới máy chủ")
                    setError(true);
                } else {
                    setTitleError(error.message)
                    setError(true);
                }

            });
    }
    const emailRegex = /^[^ @]+@[^ @]+\.[^ @]+$/;
    const [timeLeft, setTimeLeft] = useState(30);
    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(time => time - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [timeLeft]);
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!email) {
            setTitleError("Vui lòng nhập Email.");
            setError(true);
            setIsSubmitting(false); // Đặt isSubmitting thành false nếu có lỗi không nhập
            return;
        } else if (!emailRegex.test(email)) {
            setTitleError("Email không đúng định dạng.");
            setError(true);
            setIsSubmitting(false);
            return;
        }
        if (isSubmitting) {
            return; // Ngăn chặn việc bấm nút nếu đã gửi yêu cầu trước đó
        }

        setIsSubmitting(true);

        setError(false);
        // Gọi API
        const data = {
            Email: email
        };
        fetch(urlLoginSuperAdmin, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                setIsSubmitting(false);
                if (response.status === 200) {
                    return response.json();
                } else if (response.status === 401) {
                    return response.json().then(errorData => { throw new Error(errorData.message); });
                } else if (response.status === 400) {
                    return response.json().then(errorData => { throw new Error(errorData.message); });
                }
                else if (response.status === 500) {
                    return response.json().then(errorData => { throw new Error(errorData.message); });
                } else {
                    return;

                }
            })
            .then(data => {
                if (data.success) {
                    setIsCode(true)
                    setTimeLeft(30);
                }

            })
            .catch(error => {
                setIsSubmitting(false);
                if (error instanceof TypeError) {
                    setTitleError("Không thể kết nối tới máy chủ")
                    setError(true);
                } else {
                    setTitleError(error.message)
                    setError(true);
                }

            });


    };
    // const handleEnterKeyPress = (event) => {
    //     if (event.key === 'Enter') {
    //         if (isCode)
    //             handleSubmit2();
    //         else handleSubmit();
    //     }
    // };


    return (
        <div class="container">
            <div class="row">
                <div className="col-md-6">
                    <div className="d-none d-md-block">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <img style={{ width: '60%', marginTop: '25%' }} src={`${curvedImage}`} alt="Logo" />
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
                    <div style={{ backgroundColor: 'white' }} class="card card-plain mt-9">
                        <NotificationContainer notifications={notifications} />

                        <div class="card-header pb-0 text-left bg-transparent">
                            <h3 class="font-weight-bolder text-primary">Đăng Nhập</h3>
                            <p class="mb-3">Truy Cập Vào Hệ Thống Với SuperAdmin</p>
                        </div>


                        <div class="card-body" style={{ paddingTop: '10px' }}>
                            {isCode ?
                                <form role="form" onSubmit={handleSubmit2}>
                                    <div>
                                        <label>Nhập Mã Xác Thực Đã Được Gửi Đến Email</label>
                                        <div class="mb-3">
                                            <input
                                                type="text"
                                                value={code}
                                                onChange={handleCodeChange}
                                                class="form-control"
                                                placeholder="Nhập Mã"
                                            />
                                        </div>
                                    </div>
                                    {error && <div style={{ color: 'red' }}>{titleError}</div>}
                                    <div className="text-center">
                                        <button onClick={handleSubmit2} disabled={isSubmitting} type="button" className="btn bg-gradient-primary w-100 mb-0">
                                            {isSubmitting ? 'Đang xử lý...' : 'Xác Thực'}
                                        </button>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '5px', flexDirection: 'column' }}>
                                        {
                                            timeLeft > 0 ?
                                                <span
                                                    style={{ color: '#ff8c00', textAlign: 'center' }}
                                                    onClick={(e) => { e.preventDefault(); }}
                                                >Gửi Lại Mã ({timeLeft} giây)
                                                </span>
                                                : <span
                                                    style={{ color: '#ff8c00', textAlign: 'center' }}
                                                    onClick={(e) => { e.preventDefault(); handleSubmit() }}
                                                >Gửi Lại Mã
                                                </span>
                                        }
                                        <span style={{ color: '#ff8c00', textAlign: 'center', fontWeight: 'bolder' }}
                                            onClick={() => { window.location.reload(); }}
                                        >Quay Lại</span>
                                    </div>

                                </form>
                                :
                                <form role="form" onSubmit={handleSubmit}>
                                    <label>Email</label>
                                    <div class="mb-3">
                                        <input
                                            autoFocus
                                            value={email}
                                            onChange={handleEmailChange}
                                            type="email"
                                            class="form-control"
                                            placeholder="Nhập Email"
                                            aria-label="Email"
                                            ria-describedby="email-addon"
                                            // onKeyDown={handleEnterKeyPress}
                                        />
                                    </div>
                                    {error && <p class="mb-0" style={{ color: 'red' }}>{titleError}</p>}
                                    <div className="text-center">
                                        <button onClick={handleSubmit} disabled={isSubmitting} type="button" className="btn bg-gradient-primary w-100 mt-4 mb-0">
                                            {isSubmitting ? 'Đang xử lý...' : 'Gửi Mã Xác Thực'}
                                        </button>
                                    </div>
                                </form>
                            }
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );

}
export default LoginSuperAdmin